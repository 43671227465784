import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import QRCodeSVG from 'qrcode.react';
import { NewDownload } from './new-download';

function App() {
  const [qrCode, setQrCode] = useState('');

  const triggerDownload = (imgURI, extension) => {
    var evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });
  
    var a = document.createElement('a');
    a.setAttribute('download', 'sperbtecnologia.com.br_qrcode.'+extension);
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');
  
    a.dispatchEvent(evt);
  }

  const startDownload = () => {

    // var btn = document.querySelector('button');
    var svg = document.querySelector('svg');
    var canvas = document.querySelector('canvas');

    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');
    var data = (new XMLSerializer()).serializeToString(svg);
    var DOMURL = window.URL || window.webkitURL || window;
  
    var img = new Image();
    var svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
    var url = DOMURL.createObjectURL(svgBlob);
  
    img.onload = function () {
      ctx.drawImage(img, 0, 0);//, 512, 512);
      DOMURL.revokeObjectURL(url);
  
      var imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
  
      triggerDownload(imgURI, 'png');
    };
  // console.log(url)
    img.src = url;
  }

  const startDownloadSVG = () => {

    var svg = document.getElementById("qrcodesvg");

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);
    
    //add name spaces.
    if(!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)){
        source = source.replace(/^<svg/, '<svg xmlns="http://www.w3.org/2000/svg"');
    }
    if(!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)){
        source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    }
    
    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
    
    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8,"+encodeURIComponent(source);
    
    //set url value to a element's href attribute.
    // document.getElementById("link").href = url;

    triggerDownload(url, 'svg');
  }
  
  
  return (
    <div className="App">
      <header className="App-header">
        <div className="form">
        <input
          value={qrCode}
          placeholder="Insira aqui o texto do QRCode"
          onChange={(e) => setQrCode(e.target.value)}></input>
        
        <div className="qrcode">
        
          <QRCodeSVG id="qrcodesvg" renderAs="svg" size={380} value={qrCode} level={'H'}/>
        
        </div>

        <canvas id="canvas"
        width={380}
        height={380}></canvas>
        
        <button
          onClick={startDownloadSVG}>
          Download SVG
        </button>
        <button
          onClick={startDownload}>
          Download PNG
        </button>
        {/* <Trigger>Click to download</Trigger> */}
        <p className="madeby" >Feito com ♥ por <a
          className="App-link"
          href="https://sperbtecnologia.com.br"
          target="_blank"
          // rel="noopener noreferrer"
        >
          Sperb Tecnologia
        </a></p>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        </div>
      </header>
    </div>
  );
}

export default App;
